.smi {
    padding-top: 10px;
    background-color: white;
}

.smi__title {
    padding-top: 100px;
    margin-bottom: 75px;
}
.smi__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.smi__item {
    width: 20%;
}


.smi__item:hover img{
    transition: all ease-in-out 0.3s;
    transform: scale(1.015, 1.015);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}

.smi__item:hover a{
    transition: all ease-in-out 0.3s;
    color: rgb(0, 0, 0);
   
}



.smi__item-link {
    font-size: 1em;
    color: rgba(37, 35, 35, 1);
}


.smi__item-link:hover {
    color: rgba(37, 35, 35, 1);
}



.smi__item-img {
    margin-bottom: 15px;
}


@media (max-width: 768px) {
    .smi__list {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .smi__item {
        width: 48%; /* Два блока в ряду */
        margin-bottom: 50px; /* Добавим отступ между блоками */
    }
}

