
/* Добавляем анимацию плавного открытия */
@keyframes chatbotSlideIn {
    0% {
        opacity: 0;
        transform: translateY(80px); /* Начальная позиция ниже экрана */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Конечная позиция */
    }
}

.chatbot {
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 25vw;
    height: 60vh;
    background-color: #2D333E;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: none;
    flex-direction: column;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #22262F, #1C1F26);
    animation: chatbotSlideIn 0.3s ease-out;
    transition: all 0.5s ease; /* Плавный переход */
  
}

.chatbot-header__title {
    font-weight: 200;
    margin-right: 1em;
}

.chatbot-header {
    font-weight: 200;
    background-color: #1d212880;
    color: #fff;
    padding: 20px 20px;
    font-size: 1em;
    font-weight: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    border-bottom: 1px solid #444;
}


.chat-title {
    font-weight: 200;
    padding-right: 30px;
}

.chatbot-messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #1C1F26;
    color: #fff;
}

.message {
    font-size: 1.3em;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    max-width: 100%;
    word-wrap: break-word;
}

.message.user {
    font-size: 1.3em;
    background-color: inherit;
    color: #ffffff;
    align-self: flex-end;
    text-align: right;
}

.message.bot {
    font-size: 1.3em;
    background-color: #06070D;
    color: #fff;
    align-self: flex-start;
    text-align: left;
}

.chatbot-input {
    display: flex;
    border-top: 1px solid #ffffff;
    background-color: #2D333E;
}

.chatbot-input input {
    font-size: 1.2em;
    touch-action: manipulation;
    flex: 1;
    padding: 20px 10px;
    border: none;
    background: none;
    color: #fff;
    outline: none;
    word-wrap: break-word;
}

.chatbot-input button {
    padding: 20px;
    background-color: #21262d;
    border: none;
    color: #05153c;
    cursor: pointer;
    transition: background-color 0.5s ease;
    color: #FFF;
}

.chatbot-input button:hover {
    background-color: #1d2026;
}


@keyframes pulse-shadow {
    0% {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3), 0 0 5px rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3);
    }
    100% {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3), 0 0 5px rgba(255, 255, 255, 0.2);
    }
}

#chatbot-toggle {
    z-index: 1000;
    position: fixed;
    bottom: 30px;
    right: 20px;
    width: 75px;
    height: 75px;
    background-color: #06070D;
    border-radius: 50%;
    color: #fff;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    animation: pulse-shadow 2s infinite; /* Добавляем анимацию */
    transition: all ease-in-out 0.5s;
}

#chatbot-toggle:hover {
    transform: scale(1.2);
}


#chatbot-close {
    background-color: inherit;
    border: none;
    color: #FFF;
    font-size: 1.7em;
    font-weight: 100;
}

#chat-notification {
    position: fixed;
    bottom: 120px; /* чуть выше кнопки чата */
    right: 40px;
    background-color: rgba(0, 0, 0, 0.945);
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1.4em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 100;
    opacity: 0.9;
    display: none; /* по умолчанию скрыто */
}

/* Стили для мобильных устройств */
@media (max-width: 768px) {
    .chatbot {
        width: 100%; /* На всю ширину экрана */
        height: 80%; /* Увеличиваем высоту для удобства */
        bottom: 0; /* Прижимаем к нижнему краю экрана */
        right: 0;
        border-radius: 0; /* Убираем закругления */
    }

    .chatbot-header {
        font-size: 1.2em; /* Увеличиваем шрифт для удобства чтения */
        padding: 15px; /* Немного уменьшаем отступы */
    }

    .chatbot-messages {
        padding: 10px; /* Сужаем отступы для экономии пространства */
        font-size: 1.3em; /* Уменьшаем шрифт */
    }

    .message {
        padding: 8px; /* Сужаем отступы сообщений */
        font-size: 1.3em; /* Делаем текст сообщений меньше */
    }

    .chatbot-input {
        padding: 10px;
    }

    .chatbot-input input {
        padding: 15px 10px; /* Уменьшаем размер полей */
        font-size: 0.9em;
    }

    .chatbot-input button {
        padding: 10px;
        font-size: 0.9em;
    }

    #chatbot-toggle {
        width: 60px; /* Уменьшаем размер кнопки */
        height: 60px;
        bottom: 20px;
        right: 20px;
    }

    #chat-notification {
        bottom: 90px; /* Корректируем позицию уведомления */
        right: 20px;
        font-size: 1em;
        padding: 8px 12px;
    }
}

/* Стили для сверхмаленьких экранов (например, телефонов) */
@media (max-width: 480px) {
    .chatbot {
        height: 55%; /* Почти на весь экран */
    }

    .chatbot-header {
        font-size: 1em; /* Ещё больше уменьшаем шрифт */
    }

    .chatbot-messages {
        font-size: 1.3em;
    }

    .chatbot-input input {
        padding: 10px 8px;
        font-size: 1.3em;
    }

    .chatbot-input button {
        padding: 8px;
        font-size: 1.3em;
    }
}




