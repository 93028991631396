/** Articles **/

#chatbot-toggle.article {

  background-color: bisque;
}



.header.article {
  display: inline;
  color: #000;
}



.article__header-link {
  color: #000;
}

.article a:hover {
  color: inherit;
}

.section__upper-list.block-white.article {
  color: #161A20;
  margin-bottom: 10px;
}


.article {
  background-color: #FFF;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
}

.header .article {
  padding: 10px 0 50px;
  display: flex;
  flex-wrap: wrap;
  color: #000;
}


 

.article .wrapper {
  padding: 0 175px;
}

.article .section__upper-list {
  margin-bottom: 5px;
  color: black;
}


.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 30px;
  color: #152824;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: -0.2px;
  opacity: 0.90;
  text-align: left;
}

.breadcrumb__item+.breadcrumb__item::before {
  display: inline-block;
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: #6c757d;
  content: "/";
}

.article__main {
  font-family: 'Roboto', sans-serif;
  margin-right: 80px;
  padding-bottom: 5em;
}

.article__img {
  width: 100%;
  margin-bottom: 27px;
  height: 400px;
  border-radius: 15px;
}

.article__data {
  display: inline-block;
  color: #152739;
  font-size: 1.1em;
  text-transform: uppercase;
  opacity: 0.40;
  margin-bottom: 24px;
}


.article__main h1 {
  color: #152739;
  font-size: 2em;
  line-height: 1em;
  letter-spacing: -1px;
  margin-bottom: 1em;
}

.article__main h2 {
  font-size: 1.6em;
  margin-bottom: 1.5em;
  letter-spacing: -1px;
  font-weight: 500;
}

.article__main h3 {
  color: #152739;
  font-size: 1.3em;
  letter-spacing: -0.5px;
  margin-bottom: 0.8em;

}


.article__main h4 {
  color: #152739;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  margin-bottom: 0.8em;

}

.article__main p {
  text-align: justify;
  color: #000;
  font-size: 1.2em;
  line-height: 1.4em;
  opacity: 0.8;
  margin-bottom: 0.9em;
  font-weight: 200;
}


.article__main ul {
  margin-bottom: 1.8em;
  list-style-type: disc;
  list-style-position: inside;
}

strong {
  font-weight: 600;
}



.article__main li {
  text-align: justify;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1.1em;
  list-style-position: inside;
  line-height: 140%;
}


.article__img.right {
  padding-left: 41px;
}

.article__text p {
  margin-bottom: 1em;
  line-height: 120%;
}

.article__text p>span {
  font-size: 1.2em;
  font-weight: 700;
  font-style: italic;
}


.article__aside {
  max-width: 350px;
  margin-top: 25vh;
}

.article__aside-news {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 2em;
}

.article__aside-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}



.article__aside-item {
  padding-bottom: 20px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(47, 34, 34, 0.20);
}


.article__aside-title {
  color: #2F2222;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.article__aside-data {
  color: rgba(47, 34, 34, 0.40);
  font-size: 1.1em;
}

.article__aside-form {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: rgba(93, 113, 221, 0.05);
  padding: 41px 30px 50px;
}

.article__aside-label {
  color: #2F2222;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 32px;
}

.article__aside-input {
  padding: 13px 32px;
  border-radius: 20px;
  background: #FFF;
  border: none;
  margin-bottom: 21px;

}

.article__aside-button {
  border-radius: 20px;
  border: none;
  background: #5D71DD;
  color: #FFF;
  font-size: 1.1rem;
  padding: 13px 68px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.articles__link {
  display: flex;
  align-items: center;
  margin: 30px 15px 30px 0;
}

.articles__link-svg {
  width: 75px;
  height: 75px;
  padding-right: 15px;
}


.blog__subscrible-subtitle p {
  text-align: center;
}


/** Articles List **/

.article__title {
  margin-bottom: 50px;
}

.article__list {
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;

  gap: 40px;
  margin-bottom: 5em;
}

.article__item-article {
  width: 45%;
}


.article__item:hover .article__item-picture {
  filter: brightness(1);
  transform: scale(1.01);
}

.article__item-article {
  position: relative;

}

.article__item-picture {
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  overflow: hidden;

}

.picture-1 {}

.article__item-img {
  display: block;
  filter: brightness(0.6);
  width: 100%;
  height: 25vh;
  -o-object-fit: cover;
  object-fit: cover;
  transition: filter 1s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.hashtag {
  position: absolute;
  top: -1px;
  right: 5px;
  padding-top: 6px;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 0.8em;
}

.hashtag-block {
  position: absolute;
  right: 0;
  padding: 4px 14px;
  display: inline-block;
  background-color: #333;
  color: #fff;
  text-align: right;
}

.article__item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.1em;
  margin: 8px 0;
}

.article__item-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.7em;
  margin-bottom: 8px;
}

.article__item-link {
  display: inline-block;
  width: 100%;
  color: inherit;
}

.article__item-block {
  display: flex;
  align-items: center
}

.article__item-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  display: inline-block;
}

.article__item-date {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.3em;
  color: #999;
}

.article__item-container {
  position: absolute;
  padding: 30px 90px 30px 30px;
  bottom: 0;
}

.article__item-big .article__item-img {
  display: block;
  height: 60vh;
}

.article__item-big .article__item-title {
  margin-bottom: 9px;
  font-size: 1.4em;
  line-height: 1.5em;
  color: #fff;
}

.article__item-big .article__item-subtitle {
  margin-bottom: 19px;
  font-size: 0.8em;
  line-height: 1.4em;
  color: #fff;
}

.article__item-big .article__item-block {
  margin-bottom: 13px;
}

.article__item-big .article__item-date {
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.4em;
  color: #fff;
}

.article__item-btn {
  padding: 12px 21px;
  display: inline-block;
  font-weight: 700;
  font-size: 0.8em;
  line-height: 1em;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease-out;
  transition-duration: .3s;
  border: 2px solid #ff6e30;
  color: #fff;
}


.blog__subscribe {
  width: 100%; /* Вместо 100vw */
  margin: 0 auto;
  position: static; /* Убедитесь, что не используется relative/absolute, если это не требуется */
}


.article {
  display: flex;
  flex-wrap: nowrap; /* Чтобы избежать переноса */
  justify-content: space-between;
  align-items: flex-start;
}

.article__main {
  flex: 3; /* Основная часть занимает больше места */
}

.article__aside {
  flex: 1; /* Блок справа занимает меньше места */
}