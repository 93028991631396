@media (max-width: 1500px) {


    .page__body {
        font-size: 12px;
    }

    .wrapper {
        padding: 50px 50px;
    }

    .header__upper.wrapper {
        padding: 15px 50px 15px;
    }



    /**Articles-list**/

    .article__list {}

    .article__item:nth-child(1) {
        grid-column: 1/4;
        grid-row: 1/1;
    }

    .article__item:nth-child(2) {
        grid-column: 4/8;
        grid-row: 1/1;
    }

    .article__item:nth-child(3) {
        grid-column: 8/13;
        grid-row: 1/1;
    }

    .article__item:nth-child(4) {
        grid-column: 1/13;
        grid-row: 2/2;
    }

    .article__item:nth-child(5) {
        grid-column: 1/6;
        grid-row: 3/3;
    }

    .article__item:nth-child(6) {
        grid-column: 6/13;
        grid-row: 3/3;
    }

    .article__item:nth-child(7) {
        grid-column: 1/13;
        grid-row: 4/4;
    }

    .article__item:nth-child(8) {
        grid-column: 1/7;
        grid-row: 5/5;
    }

    .article__item:nth-child(9) {
        grid-column: 7/13;
        grid-row: 5/5;
    }

    .article__item:nth-child(10) {
        grid-column: 1/13;
        grid-row: 6/6;
    }

    .article__item:nth-child(11) {
        grid-column: 1/7;
        grid-row: 7/7;
    }

    .article__item:nth-child(12) {
        grid-column: 7/13;
        grid-row: 7/7;
    }

    .article__item:nth-child(13) {
        grid-column: 1/13;
        grid-row: 8/9;
    }




}

@media (max-width: 1024px) {

    .wrapper {
        padding: 50px 50px;
    }

    .sidebar__list {
        display: none;
    }

    .header__upper-item:nth-child(2) {
        display: none;
    }

    .header__upper-item:nth-child(4) {
        display: none;
    }

    .header-hero-container {
        height: 1200px;

    }

    .hero__link-block {
        display: none;
    }


    .advantage {
        padding-top: 0;

    }

    .advantage__upper {
        justify-content: center;
    }

    .advantage__upper-block {
        margin-top: 30px;
        max-width: 100%;
    }

    .advantage picture {
        display: none;
    }

    .advantage__upper-title {
        position: relative;
    }

    .advantage__block {
        display: none;
    }

    .advantage__list {
        grid-column-gap: 10px;

    }


    .advantage__item {
        height: auto;
        padding: 30px 15px;
        border-radius: 5px;
    }

    .advantage__item-title {
        font-size: 2em;
    }


    .project {
        display: none;
    }

    .section__upper-item:nth-child(2) {
        display: none;
    }

    .info {}

    .ceo__background::after {
        margin-bottom: 5px;
    }

    .ceo__container {
        display: flex;
        flex-direction: column-reverse;
    }

    .ceo__logo {
        display: none;
    }

    .ceo__block-picture {
        width: 80%;
        margin-bottom: 35px;
    }

    .ceo__block-left {
        margin-right: 0;
        width: 100%;
    }


    .request {
        margin: 0;
    }

    .calculator__container {
        flex-direction: column-reverse;
    }

    .calculator__block {
        width: 100%;
    }

    .calculator__group {
        max-width: 350px;
        border: none;
        padding: 0;
    }

    .calculator-number {
        display: none;
    }

    .calculator__main-block {

        grid-template-columns: repeat(1, 1fr);
    }





    .calculator__subtitle:nth-child(1) {
        display: none;
    }

    .article.flex {
        flex-direction: column;
    }

    .footer__main {
        width: 80%;
    }

    .team__expertise {
        display: flex;
        flex-direction: row;
    }

    .team__expertise-info {
        flex: 1;
        /* Здесь может быть дополнительный CSS для текста */
    }

    .team__expertise-img-wrapper {
        flex: 1;
        overflow: hidden;
        scroll-snap-type: x mandatory;
        /* Включаем прокрутку с выравниванием по оси X */
    }

    .team__expertise-img-list {
        display: flex;
        scroll-snap-type: x mandatory;
        /* Включаем прокрутку с выравниванием по оси X */
    }

    .team__expertise-img {
        flex: 0 0 100%;
        /* Каждое изображение занимает 100% ширины контейнера */
        scroll-snap-align: start;
        /* Выравнивание для прокрутки */
    }


    .blog__main {
        height: auto;
        margin-bottom: 46px;
    }



    /**Articles**/

    .header.article {
        background-color: #FFF;
        border-bottom: 2px solid rgba(47, 34, 34, 0.2);
        padding: 0;
    }

    .article .section__upper-list {
        display: flex;
        background-color: #FFF;
    }

    .article .wrapper {
        padding: 0 25px;
    }

    .article.flex {
        padding: 25px;
    }

    .article__aside {
        max-width: 100%;
        margin-top: 50px;
    }

    .article__main {
        margin-right: 0px;
    }

    .article__title {
        margin-top: 20px;
        ;
    }



    .email__modal_close-button {
        top: 5vh;
        /* Меньшее расстояние от верхнего края */
        right: 5vh;
        /* Меньшее расстояние от правого края */
        font-size: 1.5em;
        /* Меньший размер иконки */
    }

    .email__modal-container {
        padding-top: 10vh;
        max-width: 80vw;
        /* Больший процент использования ширины экрана */
    }

    .email__modal-form {
        padding: 60px 30px;
        /* Меньшие отступы внутри формы */
    }





}



@media (max-width: 640px) {
    .header__upper.wrapper {
        padding: 25px;
    }

    .burger {
        display: flex;
        --burger-width: 30px;
        --burger-height: 30px;
        --burger-line-height: 2px;

        position: relative;
        border: none;
        padding: 0;
        width: var(--burger-width);
        height: var(--burger-height);
        color: #FFF;
        background-color: transparent;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: var(--burger-line-height);
            background-color: currentColor;
            transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
        }

        &::before {
            top: 0;
        }

        &::after {
            top: calc(100% - var(--burger-line-height));
        }

        &__line {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: var(--burger-line-height);
            background-color: currentColor;
            transform: translateY(-50%);
            transition: transform 0.3s ease-in-out;
        }

        &--active {
            &::before {
                top: 50%;
                transform: rotate(45deg);
                transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
            }

            &::after {
                top: 50%;
                transform: rotate(-45deg);
                transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
            }

            .burger__line {
                transform: scale(0);
                transition: transform 0.3s ease-in-out;
            }
        }
    }


    .page__body {
        font-size: 9px;
    }

    .wrapper {
        padding: 35px 15px;
    }

    .sidebar {
        display: none;
    }

    .section__upper-list {
        display: none;
    }


    .header {
        padding: 17px 0;
        background: rgba(252, 252, 252, 0.05);
        backdrop-filter: blur(5px);
    }

    .header__lower-link {
        font-size: 1em;
    }

    .header__upper-item:not(:first-child) {
        display: none;
    }

    .header__lower {
        display: none;
        opacity: 0;
        transition: opacity 0.9s ease-in-out;
    }

    .header__lower.active {
        display: flex;
        opacity: 1;
        flex-direction: column;
        z-index: 3;
        height: 100vh;
    }

    .header__lower-list {
        display: flex;
        height: 50vh;
        flex-direction: column;
        font-size: 2.4em;
        font-weight: 400;
        align-content: flex-start;
        align-items: flex-start;
    }

    .header__social-list {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    }


    .header__social-icon {
        fill: rgba(255, 255, 255);
        width: 38px;
        height: 34px;
    }

    .header__social-icon {
        transition: fill .2s ease-in-out;
    }

    .header__social-icon:hover {
        fill: white;
    }

    .header-hero-container {
        min-height: 0;
        height: 100vh;
        background-size: cover;
        background-image: url(../img/hero__mobile.jpg);
    }

    .hero__img {
        position: absolute;
        right: auto;
        left: 35px;
        width: 55%;
    }


    .hero__block {
        color: white;
        margin-top: 30em;
        max-width: 100%;
        text-align: center;
    }


    .hero__title {
        margin-bottom: 12px;
    }


    .hero__subtitle {
        font-size: 1.5em;
    }

    .hero__link-block {
        display: none;
    }

    .btn {
        font-size: 2em;
        padding: 1.5em 4.7em;
    }


    .hero__lower {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: none;
        backdrop-filter: blur(5px);
    }

    .hero__lower-text {
        display: none;
    }

    .hero__lower-button {
        font-size: 2em;
        font-style: normal;
        font-weight: 700;
        line-height: 115.688%;
        letter-spacing: 0.7px;
        margin: 0 auto;
        width: 100%;
    }

    .smi__list {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .smi__item {
        width: 48%; /* Два блока в ряду */
        margin-bottom: 50px; /* Добавим отступ между блоками */
    }




    .container__btn {
        justify-content: center;
    }


    .advantage {
        margin-top: -200px;
    }


    .advantage__list {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
    }


    .advantage__item-title {
        font-size: 1.5em;
    }



    /** CEO **/
    .ceo__container {
        display: flex;
        flex-direction: column-reverse;
        align-content: center;
    }

    .ceo__block-img {
        margin-bottom: 15px;
    }

    .ceo__logo {
        display: none;
    }

    .ceo__block-left {
        margin-right: 0;
        width: 100%;
    }

    /** info **/

    .info {

        display: none;
    }


    .info__list .info__item {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        padding: 1em 1em 4em 1em;
    }

    .info__middle {
        display: none;
    }

    .info__lower {
        display: none;
    }


    /** Services **/

    .services-desktop {
        display: none;
    }


    .services {
        position: relative;
        padding: 50px 0 36px;
        color: white;
        background-color: #11101D;
        backdrop-filter: blur(5px);
    }

    .services__title {
        font-size: 2.5em;
        padding-bottom: 5px;
        margin-bottom: 51px;
    }


    .services__title:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40%;
        height: 4px;
        background-color: #FFF;
    }

    .services__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .services__item {
        margin-bottom: 35px;
        background: linear-gradient(144.39deg, #FFFFFF -278.56%, #6D6D6D -78.47%, #11101D 91.61%);
        backdrop-filter: blur(3.85604px);
        border-radius: 15px;

    }

    .service__item-link {

        padding: 1.8em 3.5em;


    }



    .services__item-discr {
        font-weight: 400;
        font-size: 1.1em;
        line-height: 22px;
        opacity: 0.8;
    }

    .services__item-title {
        font-weight: 700;
        font-size: 2em;
        line-height: 109%;
        margin-bottom: 10px;
    }

    .services__item-subtitle {
        max-width: 225px;
        font-size: 1.9em;
        line-height: 138%;
        opacity: 0.8;

    }

    .services__item-price {
        font-size: 2.3em;
    }

    .services__container {
        display: none;
    }


    .request {
        margin: 0;
    }

    .request-title {
        font-size: 1.5em;
    }

    .form {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .form-label {
        margin: 0 0 20px 0;
    }

    .form-input {
        padding: 1em 3.5em;
    }

    .form-submit {
        width: 100%;
    }

    .experts {
        display: none;
    }


    .team {
        display: none;
    }

    .team__subtitle {
        background: none;
    }

    .team__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
            "A A B"
            "A A C"
            "D E F";
        gap: 20px;

    }

    .team__item {
        border-radius: 6px;
    }

    .team__item-img {
        margin-bottom: 3px;
        width: 100%;
    }

    .team__item:nth-child(1) {
        display: inline-block;
        grid-area: A;

    }

    .team__item:nth-child(2) {
        grid-area: B;
    }

    .team__item:nth-child(3) {
        grid-area: C;
    }

    .team__item:nth-child(4) {
        grid-area: D;
    }

    .team__item:nth-child(5) {
        grid-area: E;
    }

    .team__item:nth-child(6) {
        grid-area: F;
    }

    .team__item-name {
        font-size: 1.7em;

    }

    .team__item-position {
        font-size: 1.2em;
    }

    .team__expert-discr {
        margin-bottom: 1.5em;
    }

    .team__expertise {
        flex-direction: column;
    }

    .team__expertise-text {
        margin-bottom: 1.3em;
        font-size: 2.2em;
    }

    .team__expertise-info {
        max-width: 100%;

    }

    .team__expertise-img {
        max-width: 100%;
    }

    .blog__main {
        height: auto;
        background-image: url(../img/blog_main.jpg);
        background-repeat: no-repeat;
        background-size: auto;
        color: #FFFFFF;
        border-radius: 15px;
        margin-bottom: 2em;
    }

    .blog__subscribe-input {
        width: 100%;
        height: 40px;
        margin-bottom: 1.2em;
    }

    .blog__subscribe-submit {
        width: 100%;
        height: 40px;
    }

    /** Ezxpertixe**/

    .expertise-item img:hover {
        transform: scale(1);
        z-index: 1;
    }


    /** Price **/

    .price__item-button {
        padding: 10px 10px;
    }

    .price__item-title {
        margin-bottom: 0;
    }

    .rs-label {
        width: 70px;
        height: 70px;
        font-size: 1.5em;
    }

    .calculator__subtitle {
        margin-bottom: 0;
    }

    .calculator__range {
        flex-wrap: wrap;
    }

    .rs-range {
        width: 350px;
    }


    .calculator__block {
        width: 100%;
        padding: 0 25px;
        margin-bottom: 25px;
    }

    .calculator__block-info {
        display: none;
    }

    .calculator__result {
        font-size: 2em;
        padding: 20px 0 50px 0;
    }

    .calculator__button {
        border-radius: 10px;
        width: 170px;
        height: 40px;
    }


    /** Material **/



    .materials-container {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .team__pictire-block {
        width: 100%;
    }

    .materials-info {
        width: 100%;
    }

    .programs {
        display: none;
    }

    .contact__form {
        display: none;
    }



    /** Faq **/

    .faq__discr {
        margin-bottom: 25px;
    }

    .faq__item-button {
        padding: 10px 15px;
    }

    .faq__questions-submit {
        width: 100%;
        height: 40px;
    }


    .faq__item-title {
        margin-bottom: 10px;
    }

    .faq__questions-block {
        padding: 40px 20px;
    }

    .partners__title {
        margin-bottom: 30px;
    }

    .parners__item {
        width: 10%;
        margin-bottom: 60px;
    }


    .footer {
        padding: 5px 0 10px;
        position: relative;
        background-color: #23222C;
        color: #FFF;
        background-image: url(../img/slogan.svg), url(../img/Exmlid.svg);
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: 0 0, 20% 30%;
    }

    .footer__main {
        width: 80%;
    }


    /**Articles**/

    .article__item-big .article__item-block {
        display: none;
    }

    .article__item-article {
        width: 100%;
    }

    /**About US**/


    .about-us__main-upper {
        flex-direction: column;
    }

    .about-us__main-lower {
        flex-direction: column;
    }

    .about-us__main-picture {
        width: 100%;
        height: 50vh;
    }

    .about-us__main-text {
        width: 100%;
    }



    .form__contact {
        width: 100vw;
        bottom: 0;
        height: 80px;
        padding: 0 10px;
        border-radius: 0;
        justify-content: center;
    }

    .form__contact p {
        display: none;
    }

    .form__contact-links {
        margin-right: 10px;
    }

    .form__contact-links:last-of-type {
        margin-right: 10px;
    }


    .form__contact-button {
        padding: 10px 40px;
        border-radius: 5px;
    }

    .form__contact-button:hover {
        background-color: #ff9900;
        transform: scale(1.01);
    }



}