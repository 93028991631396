
.header__upper.wrapper {
  padding: 0 100px 20px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
    padding: 20px 0 24px 0;
    background: rgba(34, 31, 31, 0.5);
    z-index: 100;
    
  }

.header_fixed {
    background-color: #15191f; 
    position: fixed; 
    top: 0;
    width: 100%;
    z-index: 1000;
}
  
  .header__upper-list {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }


  .header__upper-item:last-child {
    display: flex;
    align-items: center;
  }
  
  .header__upper-logo {
    width: 130px;
    height: 74px;
  }
  
  
  
  .header__upper-button {
    display: flex;
    border: none;
    background-color: inherit;
    color: #FFAC30;
    align-content: center;
    align-items: center;
  }

  .header__upper-button:hover {
    border: none;
    background-color: inherit;
    opacity: 0.8;
    color: #FFAC30;
  }
  
  .header__lower {
    background: rgba(21, 25, 31, 0.89);
    color: white;
    width: 100%;
    z-index: 100;
    transition: background-color 0.3s; /* Плавное изменение фона */
    background-color: rgba(21, 25, 31, 0.89); /* Начальный цвет фона */
    
  }
  
  .header__lower-list {
    display: flex;

    height: 60px;
    justify-content: space-between;
    align-items:center;
  }

  .header__lower-list.wrapper {
    padding-top: 0;
    padding-bottom: 5px;
  }
  


  .header__lower-item {
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }


  .header__upper-item span {
    font-size: 0.9em;
    color: #9AC2DB;
  }


.header__lower-link {
  font-size: 0.8vw;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
  margin-bottom: 7px;
}

.header__lower-link:hover {
  color: #fff;
}

.header__lower-link:after {  
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.header__lower-link:hover:after { 
  width: 100%; 
  left: 0; 
}



