#emailModal {
    display: none;
    position: fixed; /* Фиксированное позиционирование относительно окна браузера */
    top: 0;
    left: 0;
    width: 100vw; /* Ширина во всю ширину вьюпорта */
    height: 100vh; /* Высота во всю высоту вьюпорта */
    background-color: rgba(0, 0, 0, 0.75); /* Полупрозрачный фон */
    z-index: 9999; /* Убедитесь, что z-index выше, чем у других элементов */
    justify-content: center; /* Центрирование содержимого по горизонтали */
    align-items: center; /* Центрирование содержимого по вертикали */
    transition: opacity 0.4s ease-in-out;
    color: #FFFF;
}



.email__modal_close-button {
    position: absolute;
    top: 10vh; /* Расстояние от верхнего края контейнера квиза */
    right: 35vh; /* Расстояние от правого края контейнера квиза */
    background-color: transparent; /* Прозрачный фон */
    color: rgba(255, 255, 255, 0.5);
    border: none; /* Убрать рамку */
    font-size: 44px; /* Размер иконки закрытия */
    cursor: pointer; /* Курсор в виде указателя */
    transition: all ease-in-out 0.3s;
}


.email__modal_close-button:hover {
    color: #f09e22; /* Цвет при наведении (красный) */
}



.email__modal-container {
    padding-top: 15vh;
    max-width: 50vw;
    margin: 0 auto;
    text-align: center;
}
.email__modal-form {
    padding: 120px 120px 60px 120px;
    background-color: #26292B;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.email__modal-title {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 45px;
}

.email__modal-discr {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 40px;
}


.email__modal-form input{
    color: #FFFF;
    padding: 0 25px;
    font-weight: 300;
    outline: none;
    height: 50px;
    margin-bottom: 40px;
    background-color: inherit;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: all ease-in-out 0.4s;
}


.email__modal-form input:hover {
    background-color: rgb(45, 50, 52);
    border: 1px solid rgba(255, 255, 255);
}

.email__modal-tel {
    margin-bottom: 80px;
}







.email__modal-button {
    height: 60px;
    background-color: #FFAC30;
    box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
    border-radius: 35px;
    border: none;
    color: #FFFF;
    font-size: 16px;
    margin-bottom: 60px;
    position: relative;
    overflow: hidden;
}

.email__modal-button::before {
    content: '';
    position: absolute;
    top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
    left: -100%;
    width: 200%;
    height: 200%;
    background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
    animation: question 4.5s infinite linear;
}



.email__modal-button:hover {
    background-color: #f09e22;
}

.email__modal-social {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    font-size: 1.2em;
}


.email__modal-wa {
    color: inherit;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}

.email__modal-tg {
    color: inherit;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}

.email__modal-tg:hover {
    color:#f9d298;
}

.email__modal-wa:hover {
    color:#f9d298;
}

